import {Injectable} from '@angular/core';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {FileUpload} from './FileUpload';
import {finalize, Observable, of, from, map, switchMap, concatMap} from 'rxjs';
import {FileTypeEnum} from './FileTypeEnum';
import {last} from 'rxjs-compat/operator/last';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {FileInfo} from '../../app-item/FileInfo';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminFileUploadService {
  private basePath = '/';
  private baseStorePath = '/store';
  private storeServerPath = environment.apiEndpoint + '/api/admin/file/image/store';
  private imageServerPath = environment.apiEndpoint + '/api/admin/file/image';

  private imageVendorPath = environment.apiStorePathImage + 'vendor';

  // constructor(private realDatabase: AngularFireDatabase, private storage: AngularFireStorage) { }
  constructor(private storage: AngularFireStorage,
              private http: HttpClient) { }

  uploadFile(username: string, fileUpload: FileInfo, storeName: string, fileTypeEnum: FileTypeEnum): Observable<FileInfo> {
    console.log('1111-admin-file-upload');
    if (FileTypeEnum.COVER === fileTypeEnum) {
      return this.pushStoreCoverFile(username, fileUpload, storeName);
    }
    if (FileTypeEnum.LOGO === fileTypeEnum) {
      return this.pushStoreLogoFile(username, fileUpload, storeName);
    }
    if (FileTypeEnum.ITEM_IMAGE === fileTypeEnum) {
      return this.pushStoreItemFile(username, fileUpload, storeName, null);
    }
  }

  uploadFileWithId(username: string, fileUpload: FileInfo, storeName: string, itemId: number,
                   fileTypeEnum: FileTypeEnum): Observable<FileInfo> {
    if (FileTypeEnum.ITEM_IMAGE === fileTypeEnum) {
      return this.pushStoreItemFile(username, fileUpload, storeName, itemId);
    }
  }

  pushStoreCoverFile(username: string, fileUpload: FileInfo, storeName: string) {
    const coverPath = this.imageVendorPath + '/' + username + '/' + storeName + '/cover/' + fileUpload.data.name;
    return this.pushFileToStorage(fileUpload, coverPath);
  }

  pushStoreLogoFile(username: string, fileUpload: FileInfo, storeName: string) {
    const logoPath = this.imageVendorPath + '/' + username + '/' + storeName + '/logo/' + fileUpload.data.name;
    return this.pushFileToStorage(fileUpload, logoPath);
  }

  pushStoreItemFile(username: string, fileUpload: FileInfo, storeName: string, itemId: number) {
    const filePath = this.imageVendorPath + '/' + username + '/' + storeName + '/item/' + itemId + '/' + fileUpload.data.name;
    return this.pushFileToStorage(fileUpload, filePath);
  }

  pushFileToStorage(fileUpload: FileInfo, filePath: string): Observable<FileInfo> {
    // const filePath = this.basePath + '/' + path + fileUpload.file.name;
    const storageRef = this.storage.ref(filePath);
    const uploadTask = this.storage.upload(filePath, fileUpload.data);

    // uploadTask.snapshotChanges().pipe(
    //   last(),
    //   switchMap(() => storageRef.getDownloadURL())
    // ).subscribe(url => console.log('download url:', url));

    // uploadTask.snapshotChanges().pipe(
    //   // last(),
    //   finalize(() => {
    //     storageRef.getDownloadURL().subscribe(downloadURL => {
    //       fileUpload.url = downloadURL;
    //       // fileUpload.name = fileUpload.file.name;
    //       console.log('33333333-current file upload', fileUpload.url);
    //       // this.saveFileData(fileUpload);
    //     });
    //   })
    // // last(),
    // //   switchMap(() => fileRef.getDownloadURL())
    // ).subscribe();

    // uploadTask.then((uploadSnapshot: UploadTaskSnapshot) => {
    //   const imageUrl = uploadSnapshot.downloadURL;
    //   console.log('URL:' + imageUrl);
    // });

  //   this.uploadProgress = uploadTask.snapshotChanges()
  //     .pipe(map(res => {
  //       storageRef.getDownloadURL().subscribe(downloadURL => {
  //         fileUpload.url = downloadURL;
  //         fileUpload.name = fileUpload.file.name;
  //         // this.saveFileData(fileUpload);
  //       });
  // }));

    // uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
    //   const imageUrl = downloadURL;
    //   console.log('URL:' + imageUrl);
    // });

    // return of(uploadTask.snapshotChanges());
    // console.log('1111-snapshotChanges');

    // return from(uploadTask).pipe(
    //   switchMap((_) => storageRef.getDownloadURL()),
    // );

    return from(uploadTask).pipe(
      // last(),
      concatMap(() => storageRef.getDownloadURL()),
      map(url => {
        console.log('url', url);
        fileUpload.url = url;
        fileUpload.name = fileUpload.data.name;

        return fileUpload;
      })
    );

    // return uploadTask.then().pipe(
    //   // last(),
    //   switchMap(() => storageRef.getDownloadURL()),
    //   map(url => {
    //     console.log('url', url);
    //     fileUpload.url = url;
    //     fileUpload.name = fileUpload.file.name;
    //
    //     return fileUpload;
    //   })
    // );
  }



  // private saveFileData(fileUpload: FileUpload): void {
  //   this.realDatabase.list(this.basePath).push(fileUpload);
  // }

  // getFiles(numberItems): AngularFireList<FileUpload> {
  //   return this.realDatabase.list(this.basePath, ref =>
  //     ref.limitToLast(numberItems));
  // }

  // deleteFile(fileUpload: FileUpload): void {
  //   this.deleteFileDatabase(fileUpload.key)
  //     .then(() => {
  //       this.deleteFileStorage(fileUpload.name);
  //     })
  //     .catch(error => console.log(error));
  // }

  // private deleteFileDatabase(key: string): Promise<void> {
  //   return this.realDatabase.list(this.basePath).remove(key);
  // }

  // deleteFile(fileName: string, storeName: string, fileTypeEnum: FileTypeEnum): void {
  //   // if (FileTypeEnum.COVER === fileTypeEnum) {
  //   //   return this.deleteStoreCoverFile(fileUpload, storeName);
  //   // }
  //   if (FileTypeEnum.LOGO === fileTypeEnum) {
  //     return this.deleteStoreLogoFile(fileName, storeName);
  //   }
  //   // if (FileTypeEnum.ITEM_IMAGE === fileTypeEnum) {
  //   //   return this.pushStoreItemFile(fileUpload, storeName);
  //   // }
  // }

  deleteFileStorage(fileName: string, filePath: string): void {
    const storageRef = this.storage.ref(filePath);
    storageRef.child(fileName).delete();
  }

  deleteStoreCoverFile(username: string, fileName: string, storeName: string): void {
    const logoPath = this.imageVendorPath + '/' + username + '/' + storeName + '/cover/';
    this.deleteFileStorage(fileName, logoPath);
  }

  deleteStoreLogoFile(username: string, fileName: string, storeName: string): void {
    const logoPath = this.imageVendorPath + '/' + username + '/' + storeName + '/logo/';
    this.deleteFileStorage(fileName, logoPath);
  }

  deleteStoreImageFile(username: string, itemId: number, fileName: string, storeName: string): void {
    const storeImagePath = this.imageVendorPath + '/' + username + '/' + storeName + '/item/' + itemId + '/';
    console.log('11111111-deleteStoreImageFile', storeImagePath);
    this.deleteFileStorage(fileName, storeImagePath);
  }

  deleteStoreImageById(storeId: number, imageId: number): Observable<HttpResponse<string>> {
    console.log(this.storeServerPath + '/' + storeId + '/' + imageId);
    return this.http.delete<HttpResponse<string>>(this.storeServerPath + '/' + storeId + '/' + imageId);
  }

  markMainImage(itemId: number, colorId: number, imageId: number) {
    return this.http.put(this.imageServerPath + '/main/' + itemId + '/' + colorId + '/' + imageId, null);
  }

}
