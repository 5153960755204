export enum MessageType {
  BASIC = 'basic',
  WHITE = 'white',
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
  DANGER = 'danger',
  ERROR = 'error',
  ROSE = 'rose',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  LIGHT = 'light',
  DARK = 'dark',
  ACCENT = 'accent',
  WARN = 'warn',
  LINK = 'link'
}
