import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AboutUs} from '../../admin/admin-about-us/AboutUs';
import {environment} from '../../../environments/environment';

const aboutUsUrl = environment.apiEndpoint + '/api/public/about-us';

@Injectable()
export class AppAboutUsService {

  constructor(private http: HttpClient) {}

  readAboutUs(domain: string, storeName: string, language: string): Observable<AboutUs> {
    return this.http.get<AboutUs>(aboutUsUrl + '/' + domain + '/' + storeName + '/' + language);
  }
}
