import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageNotFoundComponent } from './page_not_found/page-not-found.component';
import { NavbarComponent } from '../core/navbar.component';
import { RouterModule } from '@angular/router';
// import { FooterComponent } from '../core/footer.component';
import {AdminFooterPageService} from '../admin/admin-footer-page/admin-footer-page.service';
import {TranslateModule} from '@ngx-translate/core';
import { AdminStaticPageService } from '../admin/admin-static-page/admin-static-page.service';
import {StarRatingComponent} from './star-rating/star-rating.component';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {AppAccountMenuComponent} from '../account-menu/app-account-menu.component';
import {AppDialog} from '../admin/admin-term-and-condition/app-dialog/app-dialog';
import {AppLanguageSelectComponent} from './app-language-select/app-language-select.component';
import {MatMenuModule} from '@angular/material/menu';



@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    MatMenuModule
  ],
  declarations: [
    PageNotFoundComponent,
    NavbarComponent,
    StarRatingComponent,
    AppAccountMenuComponent,
    AppDialog,
    AppLanguageSelectComponent
    // FooterComponent
  ],
  providers: [
    AdminFooterPageService,
    AdminStaticPageService
  ],
  exports: [
    PageNotFoundComponent,
    NavbarComponent,
    TranslateModule,
    StarRatingComponent,
    AppAccountMenuComponent,
    AppDialog,
    AppLanguageSelectComponent
    // FooterComponent
  ]
})

export class AppCommonModule {}
