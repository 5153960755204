import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';
import {AdminFileUploadComponent} from './admin-file-upload.component';
// import {AngularFireStorageModule} from '@angular/fire/compat/storage';
import {AdminFileUploadService} from './admin-file-upload.service';

@NgModule({
  declarations: [
    AdminFileUploadComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    // AngularFireStorageModule
  ],
  exports: [
    AdminFileUploadComponent,
    // AngularFireStorageModule
  ],
  providers: [
    AdminFileUploadService
  ]
})
export class FileUpoloadModule { }

