import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subscription} from 'rxjs';
import {AppStorageService} from '../../storageService/app-storage.service';
import {AuthenticationService} from '../../auth/authentication.service';
import {AppLanguageSelectService} from './app-language-select.service';
import {AppLanguageDto} from './AppLanguageDto';
import {Router} from '@angular/router';

@Component({
  selector: 'app-language-select',
  templateUrl: './app-language-select.component.html',
  styleUrl: './app-language-select.component.scss'
})
export class AppLanguageSelectComponent implements OnInit, OnDestroy {

  // @Output() appSelectedChangeLanguages = new EventEmitter<AppLanguageDto[]>();

  @Input() buttonType: string;

  appSelectedDomain: string = location.hostname;

  currentLanguageLabel = this.storageService.getLanguage();

  appLanguageList: AppLanguageDto[] = this.storageService.getSupportedLanguages();

  appLanguageSelectSubscription: Subscription;



  constructor(private storageService: AppStorageService,
              private authenticationService: AuthenticationService,
              private appLanguageSelectService: AppLanguageSelectService,
              private router: Router) {


  }

  ngOnInit(): void {
    if (!this.isEmptyDomain()) {
      this.findLanguagesByDomain();
    }
  }

  ngOnDestroy(): void {
    if (this.appLanguageSelectSubscription) {
      this.appLanguageSelectSubscription.unsubscribe();
    }
  }

  findLanguagesByDomain() {
    this.appLanguageSelectSubscription = this.appLanguageSelectService.findAllByDomain(this.appSelectedDomain)
      .subscribe({
        next: appLanguageWrapper => {
          this.appLanguageList = appLanguageWrapper.languages;
          this._sortAppLanguageList();
          this._setSupportedLanguages();
          // this.appSelectedChangeLanguages.emit(this.appLanguageList);
        }
      });
  }

  setCurrentLanguage(langCode: string, langLabel: string) {
    this.currentLanguageLabel = langLabel;
    this.storageService.setLanguage(langCode);
    this.changePathWithLanguagePrefix(langCode, this.router.url);
  }

  private changePathWithLanguagePrefix(locale: string, url: string) {
    location.replace(`/${locale}${url}`);
    // return locale === 'en' ? '/' : `/${locale}`;
  }

  private _sortAppLanguageList() {
    this.appLanguageList
      .sort((a: AppLanguageDto, b: AppLanguageDto) => (a.name).localeCompare(b.name));
  }

  private _setSupportedLanguages() {
    this.storageService.setSupportedLanguages(this.appLanguageList);
  }

  private isEmptyDomain() {
    return !this.appSelectedDomain;
  }

  private isEmptyLanguageList() {
    return !this.appLanguageList;
  }

}
