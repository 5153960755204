<div class="container">
  <div class="row text-center my-3 ">
    <div class="col">
      <div class="ps-3">
          <span i18n>We use cookies for the proper functioning and better experience of the website. For more
            information, please read our
          </span>
        <button mat-button [routerLink]="['/footer/cookie-policy']"
                target="_blank"
                color="primary"
                class="text-decoration-underline">
          <span i18n>Cookie Policy</span>
        </button>
      </div>
    </div>
  </div>
</div>

<div class="d-flex justify-content-center">
  <!--  <img src="assets/icons/cookie_Image.png" width="50px">-->
  <button type="button" mat-button
          class="me-4"
          (click)="openCookieDialog()">
    <span i18n>Settings</span>
  </button>
  <!--  <button type="button" mat-stroked-button-->
  <!--          class="me-4"-->
  <!--          (click)="onCloseClick()">-->
  <!--    <span i18n>Accept essential Cookies only</span>-->
  <!--  </button>-->
  <button type="button" mat-raised-button color="primary"
          class=""
          (click)="onAcceptAllClick()">
    <span i18n>Accept All</span>
  </button>
</div>
