
<!--<app-layout></app-layout>-->
  <!--<app-navigation></app-navigation>-->
<!--<app-login></app-login>-->

<!--<app-side-navbar></app-side-navbar>-->
<!--<app-navbar></app-navbar>-->
<!--<google-map></google-map>-->

<!--  <app-message></app-message>-->

  <router-outlet></router-outlet>

<!--<div>-->
<!--  <h1>{{ 'demo.title' | translate }}</h1>-->

<!--  &lt;!&ndash; translation: translation pipe &ndash;&gt;-->
<!--  <p>{{ 'demo.text' | translate }}</p>-->

<!--  &lt;!&ndash; translation: directive (key as attribute)&ndash;&gt;-->
<!--  <p [translate]="'demo.text'"></p>-->

<!--  &lt;!&ndash; translation: directive (key as content of element) &ndash;&gt;-->
<!--  <p translate>demo.text</p>-->
<!--</div>-->

<!--{{ 'demo.title' | translate }}-->
<!--{{ 'demo.text' | translate }}-->


<!--&lt;!&ndash; translation with parameters: translation pipe &ndash;&gt;-->
<!--<p>{{ 'demo.greeting' | translate:{'name':'Andreas'} }}</p>-->

<!--&lt;!&ndash; translation: directive (key as attribute) &ndash;&gt;-->
<!--<p [translate]="'demo.greeting'" [translateParams]="{name: 'Andreas'}"></p>-->

<!--&lt;!&ndash; translation: directive (key as content of element)&ndash;&gt;-->
<!--<p translate [translateParams]="{name: 'Andreas'}">demo.greeting</p>-->


  <!--<div class="container">-->
  <!--<app-navbar></app-navbar>-->

<!--</div>-->

<!--<div class="container">-->

  <!--<router-outlet></router-outlet>-->
  <!--&lt;!&ndash;&lt;!&ndash; <div class="page-header"> &ndash;&gt;&ndash;&gt;-->
<!--  <mat-slider min="1" max="100" step="1" value="1"></mat-slider>-->


<!--</div>-->

<!--<div class="container">-->
<!--<app-footer></app-footer>-->
<!--</div>-->




