<!--<div style="background-image: url('assets/images/natrure-1.jpg'); height: 100vh;" class="bg-image">-->
<div class="section-image">

  <div class="container-fluid-login">
    <div class="card shadow ">
      <div class="card-header mt-n3 mx-3 bg-indigo ">
        <h4 i18n class="card-title text-center text-white">Reset Password</h4>
        <p i18n class="card-category text-center text-white">Password reset link will be sent to your email</p>
      </div>
      <div class="card-body mx-3">
        <div class="text-center mt-2 mb-3">
          <button mat-button routerLink="/home" color="primary" class="fw-bold fs-4 text-primary-logo">
            Monochrome
          </button>
        </div>
<!--        <form [formGroup]="resetForm" >-->
          <div class="row mt-4 mb-1">
            <div class="col">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label class=" ">
                  <mat-icon *ngIf="this.resetPassword.invalid" class="material-symbols-outlined">lock_open</mat-icon>
                  <mat-icon *ngIf="this.resetPassword.valid" class="material-symbols-outlined">lock</mat-icon>
                  <span i18n class="ms-1">Password</span>
                </mat-label>
                <input type="password" matInput #resetPassword="ngModel" placeholder="6 or more characters"
                       required
                       (keyup.enter)="sendPasswordReset()"
                       [pattern]="resetPasswordValidatorPattern"
                       [class.is-invalid]="resetPassword.invalid && resetPassword.touched" required
                       [(ngModel)]="resetPasswordEmail"/>
                <mat-error i18n *ngIf="resetPassword.invalid && resetPassword.errors.required">
                  Password is required
                </mat-error>
                <mat-error i18n *ngIf="resetPassword.invalid && resetPassword.errors.pattern">
                  Please provide a valid password
                </mat-error>
              </mat-form-field>
            </div>
          </div>
<!--        </form>-->

        <div class="mt-2 text-center">
          <!--          New to Monochrome?-->
          <button i18n mat-raised-button color="primary" class="w-100" type="button"
                  [disabled]="resetPassword.invalid"
                  (click)="sendPasswordReset()">
            Save
          </button>
        </div>
        <div class="d-flex justify-content-center mt-4">
          <button i18n  mat-button routerLink="/login" color="primary" class="">
            Sign in
          </button>
        </div>
        <div class="pt-2 text-center">
          <button i18n mat-button routerLink="/register" color="primary" class="">
            Join now
          </button>
        </div>

        <div class="row">
          <div class="text-center mt-2">
            <app-language-select [buttonType]="'button'"></app-language-select>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

<!--</div>-->
