<button mat-icon-button *ngIf="buttonType==='icon'" [matMenuTriggerFor]="menu"
        matTooltip="Language" class="mobile-search-button text-primary-logo" color="primary">
  <mat-icon>language</mat-icon>
</button>
<button mat-button *ngIf="buttonType==='button'" [matMenuTriggerFor]="menu"
        matTooltip="Language" class="mobile-search-button text-primary-logo" color="primary">
  <span i18n>Language</span>
  <mat-icon>language</mat-icon>
</button>
<mat-menu #menu="matMenu">
  <button (click)="setCurrentLanguage(lang.name, lang.value)"
          mat-menu-item *ngFor="let lang of appLanguageList">
    {{lang.value}}
  </button>
</mat-menu>
