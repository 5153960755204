import {Component, Inject, OnInit} from '@angular/core';
import {AppMessageService} from './app-message.service';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from '@angular/material/snack-bar';

@Component({
  selector: 'app-message',
  templateUrl: './app-message.component.html',
  styleUrls: ['./app-message.component.scss']
})
export class AppMessageComponent implements OnInit {
  // snackbarMessages: Message[] = [];

  constructor(private messageService: AppMessageService,
              @Inject(MAT_SNACK_BAR_DATA) public data: any,
              public snackBarRef: MatSnackBarRef<AppMessageComponent>) {

  }


  ngOnInit(): void {

  }


  success(message: string) {
    this.messageService.success(message);
  }

  // error(message: string) {
  //   this.messageService.error(message);
  // }

  danger(message: string) {
    this.messageService.danger(message);
  }

  info(message: string) {
    this.messageService.info(message);
  }

  warn(message: string) {
    this.messageService.warn(message);
  }

  primary(message: string) {
    this.messageService.primary(message);
  }

  secondary(message: string) {
    this.messageService.secondary(message);
  }

  light(message: string) {
    this.messageService.light(message);
  }

  dark(message: string) {
    this.messageService.dark(message);
  }


}
