import {
  HttpEvent, HttpHandler,
  HttpHandlerFn, HttpInterceptor,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import {Inject, inject, Injectable, LOCALE_ID} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {from, lastValueFrom, Observable, switchMap, take} from 'rxjs';
import {AuthenticationService} from './authentication.service';

@Injectable()
export class JwtFireTokenInterceptor implements HttpInterceptor {

  constructor(private authenticationService: AuthenticationService,
              private afAuth: AngularFireAuth,
              @Inject(LOCALE_ID) public locale: string) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const isNewUser = this.authenticationService.isNewUser;
    if (isNewUser) {
      return next.handle(request);
    }

    return this.afAuth.idToken.pipe(
      take(1), // <-------------- Only emit the first value!
      switchMap((token: any) => {
        console.log('=========FIREBASE_TOKEN_INTERCEPTOR=======AUTH======', token);
        if (token) {
          const cloned = request.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`,
              // 'X-Serverless-Authorization': `Bearer ${ID_TOKEN}`,
              'Accept-Language': this.locale
            }
          });
          return next.handle(cloned);
        }
        return next.handle(request);
      }));

    // return from(this.handle(request, next));
  }



// not working - firebaseUser is undefined
  // async handle(req: HttpRequest<any>, next: HttpHandler) {
  //   // if your getAuthToken() function declared as "async getAuthToken() {}"
  //   const angularFireAuth = inject(AngularFireAuth);
  //   const firebaseUser = await angularFireAuth.currentUser;
  //   const authToken = await firebaseUser?.getIdToken();
  //   console.log('=========FIREBASE_TOKEN_INTERCEPTOR=======AUTH======', this.afAuth);
  //
  //   // if your getAuthToken() function declared to return an observable then you can use
  //   // const authToken = await lastValueFrom(this.auth.getAuthToken())
  //
  //   console.log('==========================FIREBASE_TOKEN_INTERCEPTOR=========', authToken);
  //   if (authToken) {
  //     req = req.clone({
  //       setHeaders: {
  //         Authorization: `Bearer ${authToken}`
  //       }
  //     });
  //
  //     return lastValueFrom(next.handle(req));
  //   }
  //   return lastValueFrom(next.handle(req));
  // }


  // do not use subscribe in interceptor
  // this.afAuth.idToken.subscribe({
  //   next: (token) => {
  //     console.log('=========FIREBASE_TOKEN_INTERCEPTOR=======AUTH======', token, this.afAuth);
  //     if (token) {
  //       request = request.clone({
  //         setHeaders: {Authorization: `Bearer ${token}`}
  //       });
  //     }
  //   }
  // });
  //
  // return (next.handle(request));


}
