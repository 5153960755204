import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {OrderHistory} from './OrderHistory';
import {OrderView} from './OrderView';

const orderUrl = environment.apiEndpoint + '/api/admin/order/history';
const protectedOrderUrl = environment.apiEndpoint + '/api/protected/order/history';

@Injectable({providedIn: 'root'})
export class OrderHistoryService {
  constructor(private http: HttpClient) {}

  readOrderHistory(orderId: number) {
    return this.http.get<OrderHistory>(protectedOrderUrl + '/' + orderId);
  }

  findAllOrderHistories() {
    return this.http.get<OrderView[]>(protectedOrderUrl + '/all');
  }

}
