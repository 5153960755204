import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot
} from '@angular/router';
import { AuthService } from './auth.service';
import { AuthGuard } from './auth-guard.service';
// import decode from 'jwt-decode';

@Injectable()
export class RoleGuardService implements CanActivate {
  // constructor(public auth: AuthService, public router: Router) {}
  constructor(public auth: AuthGuard, public router: Router) {}
  canActivate(route: ActivatedRouteSnapshot): boolean {
    // this will be passed from the route config
    // on the data property

    // const expectedRole = route.data.expectedRole;
    const token = localStorage.getItem('token');
    // decode the token to get its payload
    // const tokenPayload = decode(token);
    // if (
    //   !this.auth.isAuthenticated() ||
    //   tokenPayload.role !== expectedRole
    // ) {
    //   this.router.navigate(['login']);
    //   return false;
    // }
    // PTODO
      const currentUserRoles = this.auth.getLoggedUserAuthorities();
      const expectedRoles = route.data.roles;
      for (const role of expectedRoles) {
        if (currentUserRoles.includes(role.toUpperCase())) {
          return true;
        }
      }
      // this.router.navigate(['/home']);
      this.router.navigate(['/items']);

    return false;
  }

  // constructor(
  //   private router: Router,
  //   private authenticationService: AuthenticationService ) {
  // }

  // canActivate(
  //   route: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot) {
  //   const currentUser = this.authenticationService.getLoggedUser();
  // const expectedRole = route.data.expectedRole;
  // if (currentUser.token === expectedRole) {
  //     return true;
  //   }
  //   this.router.navigate(['/home']);
  //   return false;
  // }

}
