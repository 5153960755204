import {Injectable} from '@angular/core';
import { User } from '../User';
import {HttpClient, HttpParams, HttpStatusCode} from '@angular/common/http';
import {Observable, tap} from 'rxjs';

import {environment} from '../../../environments/environment';
import {catchError} from 'rxjs/operators';

const url = environment.apiEndpoint + '/api/admin/users';
const registerUrl = environment.apiEndpoint + '/api/public/register';

@Injectable()
export class RegistrationService {
  constructor(private http: HttpClient) {}

  public registration(user: User) {
    console.log(user);
    return this.http
      .get(url)
      .pipe( tap( // Log the result or error
        resp => resp
      )
      );
      // .then(resp => resp)
  }

  forgotPassword(domain: string, language: string, email: string, messageSubject: string, bodyText: string): Observable<HttpStatusCode> {
    let params = new HttpParams();
    params = params.append('domain', domain);
    params = params.append('language', language);
    params = params.append('email', email);
    params = params.append('messageSubject', messageSubject);
    params = params.append('bodyText', bodyText);

    return this.http.put<HttpStatusCode>(registerUrl + '/forgot-password', params);
  }

  resetPassword(verificationCode: string, password: string): Observable<HttpStatusCode> {
    return this.http.put<HttpStatusCode>(registerUrl + '/reset-password',
      {password: password, verificationCode: verificationCode}
    );
  }
}
