import {AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {NgZone} from '@angular/core';

@Component({
  selector: 'page-not-found',
  templateUrl: 'page-not-found.component.html',
  styleUrls: ['page-not-found.component.scss']
  // providers: []
})
export class PageNotFoundComponent implements AfterViewInit  {
  @ViewChild('dinoImageId') dinoImageId: ElementRef;
  @ViewChild('cactusImageId') cactusImageId: ElementRef;

  // @ViewChild('scrollTarget') scrollTarget: ElementRef;

  dino: any;
  cactus: any;



  constructor(private ngZone: NgZone) {

    // window.addEventListener('keydown', function(e) {
    //   console.log('888888', e.keyCode);
    //   if (e.keyCode == 38) {
    //   if (window.scrollY >= 200) {
    //     window.scrollTo(0, 0);
    //     document.documentElement.style.overflow = 'hidden';
    //   }
    //   }
    // });

  }

  ngAfterViewInit(): void {
    // console.log('43333333333333333333333333', this.dinoImageId.nativeElement);
    // console.log(this.dinoImageId.nativeElement);

    // this.jumpDino();
    //
    // this.dino = this.dinoImageId.nativeElement;
    // this.cactus = this.cactusImageId.nativeElement;
    //
    // this.functionsTe();
    // document.addEventListener("keydown", function (event) {
    //   jumpDino();
    // });
  }

  functionsTe() {
    const func = setInterval(function () {
      // get current dino Y position
      const dinoTop = parseInt(window.getComputedStyle(this.dinoImageId).getPropertyValue('top'), 10);

      // get current cactus X position
      const cactusLeft = parseInt(window.getComputedStyle(this.cactusImageId).getPropertyValue('left'), 10);

      // detect collision
      if (cactusLeft < 50 && cactusLeft > 0 && dinoTop >= 140) {
        // collision
        alert('Game Over!');
      }
    }, 10);
  }


  jumpDino() {
    console.log('000000000000000000000000000000000000000');
    if (this.dinoImageId.nativeElement.classList !== 'jump') {
      console.log('11111111111111111111111111111111111111111111111111111111111111111');
      this.dinoImageId.nativeElement.classList.add('jump');

      // setTimeout(function () {
      //   console.log('22222222222222222222222222222222222222');
      //   this.dinoImageId.nativeElement.classList.remove('jump');
      // }, 300);


      this.ngZone.run(() => {
        setTimeout(() => {
          console.log('22222222222222222222222222222222222222');
          this.dinoImageId.nativeElement.classList.remove('jump');
        }, 300);
      });

    }


  }


}
