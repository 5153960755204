import {AfterViewInit, Component, ElementRef, HostListener, Inject, ViewChild} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {TermAndCondition} from '../../admin/admin-term-and-condition/TermAndCondition';

export interface TermAndConditionDialogData {
  termAndCondition: TermAndCondition;
}

@Component({
  selector: 'app-term-and-condition-dialog',
  templateUrl: 'term-and-condition-dialog.html',
  styleUrls: ['term-and-condition-dialog.scss'],
})
export class TermAndConditionDialog implements AfterViewInit {

  showAcceptButton = false;
  @ViewChild('termAndConditionScrollVar')
  elementRef: ElementRef;

  constructor(public dialogRef: MatDialogRef<TermAndConditionDialog>,
              @Inject(MAT_DIALOG_DATA) public termAndConditionParams: TermAndConditionDialogData) {

  }

  @HostListener('scroll', ['$event'])
  onTermsAndConditionScroll($event: any) {
    if (($event.target.offsetHeight + $event.target.scrollTop + 10) >= $event.target.scrollHeight) {
      this.showAcceptButton = true;
    } else {
      this.showAcceptButton = false;
    }
  }

  onCloseClick(): void {
    this.dialogRef.close();
  }

  ngAfterViewInit() {
    if (this.elementRef.nativeElement.offsetHeight === this.elementRef.nativeElement.scrollHeight) {
      this.showAcceptButton = true;
    }
  }
}
