<div class="container-fluid-login" >

  <div class="card shadow-lg">
    <div class="card-header card-header-indigo mt-n3 mx-3 bg-indigo">
      <h3 i18n class="card-title text-center text-white">Monochrome</h3>
      <h5 i18n class="card-category text-center text-white" >Marketplace</h5>
    </div>
    <div class="card-body mx-3">
      <form [formGroup]="registerSellForm" >

        <div class="row pt-3">
          <div class="col">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>
                        <span class="icon-font-24 me-1">
                          <mat-icon *ngIf="this.email.invalid" class="material-symbols-outlined me-1 ">drafts</mat-icon>
                          <mat-icon *ngIf="this.email.valid" class="material-symbols-outlined me-1 ">mail_outline</mat-icon>
                        </span>
                <span i18n >Email</span>
              </mat-label>
              <input i18n-placeholder type="email" matInput placeholder="Verification required"
                     required formControlName="email" (keyup)="toLowerCaseEmail()"/>
              <mat-error *ngIf="email.invalid">{{getErrorMessage('email')}}</mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>
                <i class="icon-font-24 me-1">
                  <mat-icon *ngIf="this.password.invalid" class="material-symbols-outlined me-1 ">lock_open</mat-icon>
                  <mat-icon *ngIf="this.password.valid" class="material-symbols-outlined me-1 " >lock</mat-icon>
                </i>
                <span i18n>Password</span>
              </mat-label>
              <input i18n-placeholder matInput [type]="showPassword ? 'text' : 'password'"
                     placeholder="6 or more characters"
                     formControlName="password" (keyup.enter)="onSubmitRegisterSellForm()">
              <button mat-icon-button matSuffix type="button"
                      (click)="showPassword = !showPassword"
                      [attr.aria-label]="'Hide password'"
                      [attr.aria-pressed]="showPassword">
                <mat-icon class="material-symbols-outlined">
                  {{showPassword ? 'visibility' : 'visibility_off'}}</mat-icon>
                <!--                        {{showPassword ? 'Hide' : 'Show'}}-->
              </button>
              <mat-error *ngIf="password.invalid">{{getErrorMessage('password')}}</mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>
                <span class="icon-font-24 me-1">
                  <mat-icon class="material-symbols-outlined me-1 ">badge</mat-icon>
                </span>
                <span i18n >EIK/PIK</span>
              </mat-label>
              <input i18n-placeholder type="text" matInput minlength="9" maxlength="9"
                     required formControlName="eik" placeholder="9 symbols" />
              <mat-error *ngIf="eik.invalid">{{getErrorMessage('eik')}}</mat-error>
            </mat-form-field>
          </div>
        </div>

<!--        <div class="row pt-3">-->
<!--          <div class="col">-->
<!--            <mat-form-field class="w-100" appearance="outline">-->
<!--              <mat-label>-->
<!--                <span class="icon-font-24 me-1">-->
<!--                  <mat-icon class="material-symbols-outlined me-1 ">badge</mat-icon>-->
<!--                </span>-->
<!--                <span i18n >Company name</span>-->
<!--              </mat-label>-->
<!--              <input i18n-placeholder type="text" matInput-->
<!--                     required formControlName="companyName"/>-->
<!--              <mat-error *ngIf="companyName.invalid">{{getErrorMessage('companyName')}}</mat-error>-->
<!--            </mat-form-field>-->
<!--          </div>-->
<!--        </div>-->

<!--        <div class="row ">-->
<!--          <div class="col">-->
<!--            <mat-form-field class="w-100" appearance="outline">-->
<!--              <mat-label >-->
<!--                <span class="icon-font-24 me-1">-->
<!--                  <mat-icon class="material-symbols-outlined me-1 ">phone</mat-icon>-->
<!--                </span>-->
<!--                <span i18n >Phone number</span>-->
<!--              </mat-label>-->
<!--              <input matInput i18n-placeholder formControlName="phoneNumber" placeholder="Verification required" required>-->
<!--              <mat-error *ngIf="phoneNumber.hasError('required')">-->
<!--                <span i18n >Phone </span><strong i18n > is required</strong>-->
<!--              </mat-error>-->
<!--              <mat-error i18n *ngIf="phoneNumber.errors">-->
<!--                Only numbers. Min length is 5 symbols-->
<!--              </mat-error>-->
<!--            </mat-form-field>-->
<!--          </div>-->
<!--        </div>-->



        <div class="row">
          <div class="text-center">
            <div class="fw-lighter pt-3">
              <span i18n>By signing up, you agree to our</span>
              <a i18n role="button" (click)="readTermsAndConditions()" class="text-decoration-none">
                Terms of Service
              </a> <span i18n>policies</span>

            </div>
            <br/>
            <button i18n mat-raised-button color="primary" class="w-75 mb-3"
                    [disabled] = "registerSellForm.invalid"
                    (click)="onSubmitRegisterSellForm()">
              Sign Up
            </button>
          </div>
        </div>

      </form>

    </div>
  </div>
</div>
