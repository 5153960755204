import {Injectable} from '@angular/core';
import {
  Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, NavigationExtras, Route, CanActivateChild,
  CanLoad
} from '@angular/router';
import {AuthenticationService} from './authentication.service';
import {RoleType} from '../user/RoleType';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    let url: string = state.url;

    if (!this.checkLogin(url)) {
      this.router.navigateByUrl(url);
      return false;
    }

    if (next.data && next.data.roles) {
      if (!this.checkHasAuthority(next.data.roles)) {
        url = 'admin';
        this.router.navigateByUrl(url);
        return false;
      }
    }

    return true;
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }

  canLoad(route: Route): boolean {
    const url = `/${route.path}`;

    return this.checkLogin(url);
  }

  checkLogin(url: string): boolean {
    if (this.authenticationService.isAuthenticatedUser) {
      return true;
    }
    // Store the attempted URL for redirecting
    this.authenticationService.redirectUrl = url;

    // Create a dummy session id
    const sessionId = 123456789;

    // PTODO this put session_ID in url
    // Set our navigation extras object
    // that contains our global query params and fragment
    // const navigationExtras: NavigationExtras = {
    //   queryParams: { 'session_id': sessionId },
    //   fragment: 'anchor'
    // };

    // Navigate to the login page with extras
    // this.router.navigate(['/login'], navigationExtras);
    this.router.navigate(['/login']);
    return false;
  }

  checkHasAuthority(rolesToCheck: RoleType) {
    let isAllowed = false;
    if (rolesToCheck) {
      const loggedUserAuthorities = this.getLoggedUserAuthorities();
      for (const role of rolesToCheck) {
        isAllowed = loggedUserAuthorities.includes(role);
        if (isAllowed) {
          return true;
        }
      }
    }
    return false;
  }

  getLoggedUser() {
    return this.authenticationService.getLoggedUserRole();
  }
  getLoggedUserAuthorities() {
    return this.authenticationService.getLoggedUserAuthorities();
  }

}

