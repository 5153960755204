import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from '../../auth/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { AppMessageService } from '../../message/app-message.service';

import {AppCookieDialogComponent} from '../../cookie-snackbar/dialog/app-cookie-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {AngularFireAuth} from '@angular/fire/compat/auth';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  // currentLanguageLabel = getLanguageKeyByValue(this.appLocaleService.getCurrentLanguage());

  constructor(
    private fb: FormBuilder,
    public authenticationService: AuthenticationService,
    private afAuth: AngularFireAuth,
    private router: Router,
    private route: ActivatedRoute,
    public _cookieLoginDialog: MatDialog,
    private messageService: AppMessageService
  ) {
    // this.authenticationService.currentUser = JSON.parse(localStorage.getItem('currentUser'));
  }

  model: any = {};
  loading = false;
  returnUrl: string;
  showPassword = false;

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParamMap.get('returnUrl');

    this.loginForm = this.fb.group({
      // email: ['', [Validators.required, Validators.email]],
      username: [null, [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      password: [null, [Validators.required, Validators.minLength(6)]],
    });

  }



  login(credentialsLogin) {
    // this.message = 'Trying to log in ...';
    const credentials = {username: this.loginForm.get('username').value, password: this.loginForm.get('password').value};
    this.authenticationService.setNewUser(false);

    this.authenticationService.loginWithFire(credentials).subscribe({
      next: (userCredentials) => {

        // this.authenticationService._user$.subscribe(user => {
        //   console.log('55555555555555555', user.email, user.displayName);
        //   this.authenticationService.currentUserSig.set(user.email);
        //   console.log(this.authenticationService.currentUserSig());
        // });

        this.authenticationService.firebaseGetTokenResult(userCredentials)
          .subscribe({
            next: tokenResult => {
              console.log('==========LOGIN=======AUTHORITIES===================',
                this.authenticationService.isAuthenticatedUser, tokenResult.claims.authorities);
              this.authenticationService.setLoggedUserAuthorities(tokenResult.claims.authorities);
              this.redirectTo();
            }
          });

        // this.authenticationService.firebaseGetToken(userCredentials)
        //   .subscribe({
        //     next: token => {
        //       console.log('==========LOGIN_SUCCESS_TOKEN', token);
        //     }
        //   });

        // this.authenticationService.authenticate(credentials)
        //   .subscribe({
        //     next: data => {
        //       this.redirectTo();
        //     }
        //   });
      }


    });
  }


  logout() {
    this.authenticationService.logout();
  }

  onSubmit() {
    // (keyup.enter) - for login form keypress - not working
    if (this.loginForm.invalid) {
      return;
    }

    const credentials = {username: this.loginForm.get('username').value, password: this.loginForm.get('password').value};
    this.login(credentials);
  }

  redirectTo() {
    if (this.authenticationService.isAuthenticatedUser) {
      if (this.returnUrl) {
        this.router.navigateByUrl(this.returnUrl);
      }

      let redirect = '/home';

      if (this.authenticationService.isAdminOrVendor()) {
        redirect = '/admin/admin-store';
      }
      this.router.navigate([redirect]);
    }
  }


  getLoginErrorMessage() {
    if (this.username.hasError('email')) {
      return $localize `Please enter a valid email`;
    }
    if (this.password.hasError('minlength')) {
      return $localize `Password must be 6 characters or more.`;
    }
    return '';
  }

  get username(): any {
    return this.loginForm.get('username');
  }
  get email(): any {
    return this.loginForm.get('email');
  }
  get password(): any {
    return this.loginForm.get('password');
  }

  openCookiePolicyLoginDialog() {
    this._cookieLoginDialog.open(AppCookieDialogComponent, { disableClose: false });
  }
}

