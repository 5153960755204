<h2 mat-dialog-title i18n>Cookie settings</h2>
<mat-dialog-content class="mat-typography">
  <form class="cookieDialogFormBlock" [formGroup]="cookieFormGroup">
    <div class="row gy-5">
      <div class="col">
        <div class="ps-3">
          <mat-slide-toggle
            formControlName="allowRequired"
            [color]="'primary'"
            [disabled]="true">
            <span i18n>Required</span>
          </mat-slide-toggle>
        </div>
        <div class="p-3">
          <span i18n>These cookies are necessary for the proper functioning of the website and for the provision of services such as
            enable navigation through the site and use of its functions, such as remembering previous actions
            when going back in the same session. Therefore, visitors cannot refuse these cookies without doing so
            affect the functioning of the website.</span>
        </div>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="row gy-5">
      <div class="col">
        <div class="ps-3 pt-3">
          <mat-slide-toggle
            formControlName="allowAnalytic"
            [color]="'primary'">
            <span i18n>Analytics</span>
          </mat-slide-toggle>
        </div>
        <div class="p-3">
          <span i18n>These cookies provide information about how the website is used, such as information that
          is collected is the Internet Protocol (IP) address of the device from which the visitor accesses the platform
          (usually used to specify a country or city); type of device the visitor is from
          access the Platform (e.g. computer, mobile phone, tablet, etc.); type of operating room
          system; browser type; specific actions taken, including pages visited, frequency
          and the duration of visits to the website; date and duration of visits. Thus we have
          an opportunity to improve the functionality of the website to provide a better visitor experience.</span>
        </div>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="row gy-5">
      <div class="col">
        <div class="ps-3 pt-3">
          <mat-slide-toggle
            formControlName="allowFunctional"
            [color]="'primary'">
            <span i18n>Functional</span>
          </mat-slide-toggle>
        </div>
        <div class="p-3">
          <span i18n>These cookies are used to improve the functionality of the website.</span>
        </div>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="row gy-5">
      <div class="col">
        <div class="ps-3 pt-3">
          <mat-slide-toggle
            formControlName="allowAdvertise"
            [color]="'primary'">
            <span i18n>Advertise</span>
          </mat-slide-toggle>
        </div>
        <div class="p-3">
          <span i18n>These cookies are used to track website visitors to gain insight into
          their interests based on the sites visited, in order to be able to offer relevant to the respective visitor
          advertisements.</span>
        </div>
      </div>
    </div>
    <!--  </div>-->
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end" class="me-4 my-3">
  <!--  <button mat-button (click)="onCloseClick()">Accept essential Cookies only</button>-->
  <button mat-button (click)="onAcceptSelectedClick()"><span i18n>Accept selected</span></button>
  <button mat-raised-button (click)="onAcceptAllClick()" cdkFocusInitial color="primary"><span i18n>Accept All</span></button>
</mat-dialog-actions>
