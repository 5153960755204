import {Injectable} from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {StaticPage} from './StaticPage';
import { StaticPageMenu } from './StaticPageMenu';
import { StaticPageHeader } from './StaticPageHeader';
import { StaticPageWrapper } from './StaticPageWrapper';

const staticPageUrl = environment.apiEndpoint + '/api/admin/static-page';

@Injectable({
  providedIn: 'root'
})
export class AdminStaticPageService {

  constructor(private http: HttpClient) {
  }

  findStaticPages() {
    console.log('findStaticPage');
    return this.http.get<StaticPage[]>(staticPageUrl + '/find/all');
  }

  createStaticPage(staticPage: StaticPage) {
    return this.http.post(staticPageUrl + '/create', staticPage);
  }

  createStaticPageHeader(staticPage: StaticPageHeader) {
    return this.http.post(staticPageUrl, staticPage);
  }

  updateStaticPageMenuOrder(domain: string, menuOrder: StaticPageMenu) {
    return this.http.put(staticPageUrl + '/menu/' + domain, menuOrder);
  }

  updateStaticPage(staticPage: StaticPage) {
    console.log('updateStaticPage---', staticPage);
    return this.http.put(staticPageUrl, staticPage);
  }

  deleteStaticPage(domain: string, pageName: string) {
    return this.http.delete(staticPageUrl + '/' + domain + '/' + pageName);
  }

  readById(id: number) {
    return this.http.get(staticPageUrl + id);
  }

  loadPages(domain: string, language: string) {
    return this.http.get<string[]>(environment.apiEndpoint + '/api/public' + '/static-page' + '/' + domain + '/' + language);
  }

  findAll() {
    return this.http.get<StaticPage[]>(staticPageUrl + '/find/all');
  }

  findStaticPageBodyByPageName(domain: string, pageName: string) {
    return this.http.get<StaticPage>(staticPageUrl + '/read/' + domain + '/' + pageName);
  }

  findStaticPagesMenuAdmin(domain: string, language: string) {
    let params = new HttpParams();
    params = params.append('language', language);
    return this.http.get<StaticPageMenu[]>(staticPageUrl + '/menu/' + domain, {params: params});
  }

  findAllStaticPagesWithoutBody(domain: string) {
    let params = new HttpParams();
    params = params.append('domain', domain);
    return this.http.get<StaticPageWrapper>(staticPageUrl, {params: params});
  }
}
