// import defaultLanguage from 'assets/i18n/en.json';
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {GoogleMap, MapInfoWindow, MapMarker} from '@angular/google-maps';
import {AuthenticationService} from './auth/authentication.service';
import {ActivatedRoute, NavigationEnd, Params, Router, RoutesRecognized} from '@angular/router';
import { filter } from 'rxjs/operators';
import {environment} from '../environments/environment';
// import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  constructor() {
    // translate.setTranslation('en', defaultLanguage);
    // translate.setDefaultLang('en');
    // const translation = translate.getTranslation(navigator.language);
    console.log('translations----');
    console.log(environment.production); // Logs false for development environment
  }

  // title = 'login form';
  // body = 'Незабравимите цитати на Пабло Неруда' +
  // 'Умира бавно този, който не променя живота си, когато е недоволен от работата или любовта си, ' +
  // 'който не рискува сигурното за неизвестното, за да преследва една мечта, който не се решава,' +
  // 'поне веднъж в живота си, да избяга от мъдрите съвети... Не умирай бавно! Живей днес! Рискувай днес!' +
  // 'Действай днес! Не се оставяй да умираш бавно! Не забравяй да бъдеш щастлив!';


  // options: google.maps.MapOptions = {
  //   zoomControl: false,
  //   scrollwheel: false,
  //   disableDoubleClickZoom: true,
  //   mapTypeId: 'hybrid',
  //   maxZoom: 15,
  //   minZoom: 8,
  // }

  ngOnInit(): void {
    // PTODO use in path not in query params!!!
    // this.router.navigate(['/items', 'Phones']);
    // this.route.queryParams
    //   .subscribe(
    //     (params: Params) => {
    //       this.urlContainsAdmin = params['pageName'];
    //       console.log('urlContainsAdmin', this.urlContainsAdmin);
    //     }
    //   );

    // this.router.events
    //   .pipe(filter(event => event instanceof NavigationEnd))
    //   .subscribe(() => {
    //     console.log(this.activatedRoute.root);
    //   });
    //
    // // // console.log('dddddddddaaaaaaaa', this.activatedRoute.snapshot.url);
    // if (this.activatedRoute && this.activatedRoute.url) {
    //   // console.log('ddadsdfsfsf', location);
    // } else {
    //   this.router.navigate(['items'], { queryParams: { pageName: 'Phones' } });
    // }


    // var mapCanvas = document.getElementById("map");
    // var myCenter = new google.maps.LatLng(53.131083, 23.154742);
    // var mapOptions = {
    //   center: myCenter,
    //   zoom: 15,
    //   mapTypeId: google.maps.MapTypeId.ROADMAP
    // };
    // this.googleMap = new google.maps.Map(mapCanvas, mapOptions);
    // google.maps.event.addListener(map, 'click', function (event) {
    //   this.placeMarker(event);
    // });
  }


}
