<div class="page-title-overlap bg-purple pt-4"></div>

<div class="container pb-5 mb-2 mb-md-4">

  <div class="row">
    <section class="col-12">
      <div class="pt-3 pb-4 pb-sm-5 mt-1">
        <h3 class="text-light">Page Not Found</h3>
      </div>

      <div class="d-flex flex-column pt-5 text-center ">
        <!--<span class="text-muted">-->
        <!--        <h1>Oops!</h1>-->
        <!--</span>-->
        <div class="image-container-me">
          <img src="assets/common-error-page-404.png"/>
        </div>
        <span class="text-muted py-3">

            Sorry, the page you were looking for was not found.
          </span>

        <button routerLink="/items"
                mat-stroked-button color="primary" class="d-block w-100 mt-4" type="button">
          <mat-icon class="material-symbols-outlined align-middle text-muted">cached</mat-icon>
          Continue shopping
        </button>
      </div>

    </section>


<!--    <div   id="target-div" role="application" tabindex="0" title="Dino game, play" class="runner-container"  style="width: 600px; height: 150px;">-->
<!--    <iframe  src="https://chromedino.com"   frameborder="0" scrolling="no" width="600px" height="150px" loading="lazy"></iframe>-->
<!--    <style type="text/css">iframe { position: absolute; width: 600px; height: 270px; z-index: 999; }</style>-->
<!--    </div>-->


<!--    <div class="game">-->
<!--      <div id="dinoImageId" #dinoImageId></div>-->
<!--      <div id="cactusImageId" #cactusImageId></div>-->
<!--    </div>-->
  </div>
</div>

<!--<script>-->
<!--  $('#target-div').load('https://chromedino.com #main-frame-error');-->

<!--</script>-->











