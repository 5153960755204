import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {FooterPage} from './FooterPage';
import {FooterMenu} from './FooterMenu';

const footerPageUrl = environment.apiEndpoint + '/api/admin/footer';
const footerPublicUrl = environment.apiEndpoint + '/api/public/footer';

@Injectable()
export class AdminFooterPageService {

  footerPages: FooterPage[];

  constructor(private http: HttpClient) { }


  findFooterPublicPages(domain: string, language: string) {
    console.log('findFooter');
    return this.http.get<FooterMenu>(footerPublicUrl + '/' + domain + '/' + language);
  }

  createPage(footerPage: FooterPage) {
    return this.http.post(footerPageUrl, footerPage);
  }

  updatePage(footerPage: FooterPage) {
    return this.http.put(footerPageUrl, footerPage);
  }

  deleteFooterPage(domain: string, pageName: string) {
    return this.http.delete(footerPageUrl + '/' + domain + '/' + pageName);
  }

  readById(id: number) {
    return this.http.get(footerPageUrl + id);
  }

  findAllByDomain(domain: string) {
    return this.http.get<FooterMenu[]>(footerPageUrl + '/all/' + domain);
  }

  findFooterPageBodyByPageName(domain: string, pageName: string) {
    return this.http.get<FooterPage>(footerPageUrl + '/' + domain + '/' + pageName);
  }

  findFooterPagePublic(domain: string, pageName: string, language: string) {
    return this.http.get<FooterPage>(footerPublicUrl + '/' + domain + '/' + pageName + '/' + language);
  }

  updatePageMenuOrder(domain: string, menuOrder: FooterMenu) {
    return this.http.put(footerPageUrl + '/menu/' + domain, menuOrder);
  }

}
