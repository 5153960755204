import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {TermAndCondition} from '../admin/admin-term-and-condition/TermAndCondition';

const baseProtectedUrl = environment.apiEndpoint + '/api/protected/term-and-condition';
const basePublicUrl = environment.apiEndpoint + '/api/public/term-and-condition';

@Injectable()
export class AppTermAndConditionService {

  constructor(private http: HttpClient) {}


  findAllTermsAndConditions(domain: string, role: string, language: string): Observable<TermAndCondition[]> {
    return this.http.get<TermAndCondition[]>(baseProtectedUrl + '/' + domain + '/' + role + '/' + language);
  }

  acceptTermAndCondition(termAndConditionId: number, username: string) {
    let params = new HttpParams();
    params = params.append('username', username);

    return this.http.patch(baseProtectedUrl + '/' + termAndConditionId, {}, {params: params});
  }

  findAllTermAndConditionByDomainAndLanguage(domain: string, role: string, language: string): Observable<TermAndCondition> {
    return this.http.get<TermAndCondition>(basePublicUrl + '/' + domain + '/' + role + '/' + language);
  }

}
