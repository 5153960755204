import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule} from '@angular/forms';
import {AppRoutingModule} from '../app-routing.module';
// import {LoginComponent} from '../user/login/login.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    AppRoutingModule
  ],
  declarations: [
    // LoginComponent
  ]
})
export class AuthModule {

}
