import {NgModule} from '@angular/core';
import {RegistrationService} from './registration/registration.service';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
// import {GoogleMapsModule} from '@angular/google-maps';
import {AppUserProfileComponent} from './profile/app-user-profile.component';
import {MatDividerModule} from '@angular/material/divider';
import {MatBadgeModule} from '@angular/material/badge';
import {MatIconModule} from '@angular/material/icon';
import {AppCommonModule} from '../common/app-common.module';
import {AppUserProfileService} from './profile/app-user-profile.service';
import {UserService} from './user.service';
import {OrderHistoryService} from './app-orders-history/order-history.service';
import {AppTermAndConditionService} from '../app-term-and-condition/app-term-and-condition.service';
// import {MatSidenavModule} from '@angular/material/sidenav';
// import {MatMenuModule} from '@angular/material/menu';
// import {MatToolbarModule} from '@angular/material/toolbar';
// import {MatTreeModule} from '@angular/material/tree';
// import {MatListModule} from '@angular/material/list';
// import {MatSortModule} from '@angular/material/sort';
// import {MatGridListModule} from '@angular/material/grid-list';
// import {MatTableModule} from '@angular/material/table';
// import {MatIconModule} from '@angular/material/icon';
// import {MatPaginatorModule} from '@angular/material/paginator';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    AppCommonModule,
    DragDropModule,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    ReactiveFormsModule,
    MatCardModule,
    MatButtonModule,
    // GoogleMapsModule,
    MatDividerModule,
    MatBadgeModule,
    MatIconModule
    // MatToolbarModule,
    // MatButtonModule,
    // MatSidenavModule,
    // MatIconModule,
    // MatListModule,
    // MatTableModule,
    // MatPaginatorModule,
    // MatSortModule,
    // MatGridListModule,
    // MatCardModule,
    // MatMenuModule,
    // MatTreeModule,
    // DragDropModule,
    // MatInputModule,
    // MatSelectModule,
    // MatRadioModule,


  ],
  declarations: [
    // LoginComponent,
    // RegistrationComponent,
    AppUserProfileComponent,
  ],
  providers: [
    RegistrationService,
    AppUserProfileService,
    UserService,
    OrderHistoryService,
    AppTermAndConditionService
  ],
  exports: [
    // LoginComponent
  ]
})
export class UserModule {

}
