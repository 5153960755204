import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {AppLanguageDtoWrapper} from './AppLanguageDto';

const languageUrl = environment.apiEndpoint + '/api/public/language';

@Injectable({
  providedIn: 'root'
})
export class AppLanguageSelectService {

  constructor(private http: HttpClient) {
  }

  findAllByDomain(domain: string): Observable<AppLanguageDtoWrapper> {
    return this.http.get<AppLanguageDtoWrapper>(languageUrl + '/' + domain);
  }

  readByDomainAndName(domain: string, code: string): Observable<AppLanguageDtoWrapper> {
    return this.http.get<AppLanguageDtoWrapper>(languageUrl + '/' + domain + '/' + code);
  }
}
