import {AfterViewInit, Component, inject} from '@angular/core';
import {AppMessageService} from '../../../message/app-message.service';
import {RegistrationService} from '../../registration/registration.service';
import {ActivatedRoute, Router, RouterModule} from '@angular/router';
import {AuthenticationService} from '../../../auth/authentication.service';
import {MatButtonModule} from '@angular/material/button';
import {FormBuilder, FormGroup, FormsModule, Validators} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatMenuModule} from '@angular/material/menu';
import {AppCommonModule} from '../../../common/app-common.module';

const emailValidator = '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$';

@Component({
  selector: 'app-reset-password',
  // standalone: true,
  // imports: [
    // FormsModule,
    // MatFormFieldModule,
    // // MatButtonModule,
    // // RouterModule,
    // //
    // // CommonModule,
    // // MatIconModule,
    // // MatInputModule,
    // // MatMenuModule,
    // AppCommonModule,
  // ],
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.scss'
})
export class ResetPasswordComponent {

  private activatedRoute = inject(ActivatedRoute);
  resetPasswordEmail: string;
  resetPasswordValidatorPattern: string;
  // resetForm: FormGroup;

  // currentLanguageLabel = getLanguageKeyByValue(this.appLocaleService.getCurrentLanguage());
  mode: string;
  oobCode: string;
  continueUrl: string;
  lang: string;

  constructor(private fb: FormBuilder,
              private router: Router,
              private registrationService: RegistrationService,
              private messageService: AppMessageService,
              private authenticationService: AuthenticationService) {

    this.resetPasswordValidatorPattern = '^[a-z0-9._%+-]{6,}$';

    // this.resetForm = this.fb.group({
    //   // email: ['', [Validators.required, Validators.email]],
    //   password: [null, [Validators.required, Validators.minLength(6)]],
    // });

  }

  sendPasswordReset(): void {
    this.activatedRoute.queryParamMap.subscribe((params) => {
      const code = params.get('token')!;
      this.registrationService.resetPassword(code, this.resetPasswordEmail)
        .subscribe({
        next: response => {
          this.router.navigateByUrl('login');
        }
      });
    });
  }

  sendPasswordResetEmailWithFire(): void {
    if (!this.resetPasswordEmail) {
      return;
    }
    this.activatedRoute.queryParamMap.subscribe((params) => {
      // const snapshot = this.activatedRoute.snapshot.params['mode'];
      const mode = params.get('mode')!;
      const actionCode = params.get('oobCode')!;
      const continueUrl = params.get('continueUrl')!;
      const lang = params.get('lang')! || 'en';


      // Handle the user management action.
      switch (mode) {
        case 'resetPassword':
          // Display reset password handler and UI.
          this.authenticationService.handleResetPassword(actionCode, continueUrl, lang, this.resetPasswordEmail);
          break;
        case 'recoverEmail':
          // Display email recovery handler and UI.
          // this.handleRecoverEmail(actionCode, lang);
          break;
        case 'verifyEmail':
          // Display email verification handler and UI.
          // this.handleVerifyEmail(actionCode, continueUrl, lang);
          break;
        default:
        // Error: invalid mode.
      }

    });
  }


}
