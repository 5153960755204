import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MAT_DIALOG_SCROLL_STRATEGY, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {ScrollStrategy} from '@angular/cdk/overlay';
import {AppStorageService} from '../../storageService/app-storage.service';
import {MatCardModule} from '@angular/material/card';
import {FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatDividerModule} from '@angular/material/divider';
import {DomainDto} from '../../admin/admin-domain/DomainDto';
import {AppCookieDto} from '../../common/model/AppCookieDto';

// interface DialogData {
//   message: string;
// }

@Component({
  selector: 'app-app-modal-cookie',
  templateUrl: './app-cookie-dialog.component.html',
  styleUrls: ['./app-cookie-dialog.component.scss'],
  standalone: true,
  imports: [
    // MatCardModule,
    // // MatRadioModule,
    // FormsModule,
    // MatButtonModule,
    MatSlideToggleModule,
    MatDividerModule,
    MatButtonModule,
    MatDialogModule,
    ReactiveFormsModule,
  ],
})
export class AppCookieDialogComponent implements OnInit {

  cookieFormGroup: FormGroup;
  cookieSettingsDto: AppCookieDto = this.storageService.getUserCookieSettings();

  constructor(private _formBuilder: FormBuilder,
              public dialogRef: MatDialogRef<AppCookieDialogComponent>,
              // @Inject(MAT_DIALOG_DATA) public data: DialogData,
              private storageService: AppStorageService) {

    this.cookieFormGroup = this._formBuilder.group({
      allowRequired: new FormControl({ value: 'true', disabled: true }),
      allowAnalytic: [''],
      allowFunctional: [''],
      allowAdvertise: [''],
    });

  }

  ngOnInit(): void {
    this.setCookieSettings();
  }

  alertFormValues(formGroup: FormGroup) {
    alert(JSON.stringify(formGroup.value, null, 2));
  }

  onCloseClick(): void {
    this.dialogRef.close();
  }

  onAcceptSelectedClick(): void {
    this.addToStorage();
    this.onCloseClick();
  }

  onAcceptAllClick(): void {
    this.setAllAccepted();
    this.addToStorage();
    this.onCloseClick();
  }

  addToStorage() {
    this.storageService.addCookieAccepted();
    this.storageService.setUserCookieSettings(this.cookieFormGroup.value);
  }

  setCookieSettings() {
    this.cookieFormGroup.setValue({
      allowRequired: this.cookieSettingsDto.allowRequired,
      allowAnalytic: this.cookieSettingsDto.allowAnalytic,
      allowFunctional: this.cookieSettingsDto.allowFunctional,
      allowAdvertise: this.cookieSettingsDto.allowAdvertise
    });
  }

  setAllAccepted() {
    this.cookieFormGroup.setValue({
      allowRequired: true,
      allowAnalytic: true,
      allowFunctional: true,
      allowAdvertise: true
    });
  }

  get allowRequired(): any {
    return this.cookieFormGroup.get('allowRequired').value;
  }
  get allowAnalytic(): any {
    return this.cookieFormGroup.get('allowAnalytic').value;
  }
  get allowFunctional(): any {
    return this.cookieFormGroup.get('allowFunctional').value;
  }
  get allowAdvertise(): any {
    return this.cookieFormGroup.get('allowAdvertise').value;
  }
}
