<!--<div class="main-panel">-->
<div class="container-fluid-login" >
<!--  style="background-image: url('https://www.titanwolf.org/img/bg8.jpg');border-radius:5px"-->
<!--  <div class="content" style="margin-top:100px" >-->
<!--    <div class="container-fluid">-->
<!--      <div class="row">-->
<!--        <div class="col-md-6 offset-md-3">-->
          <div class="card shadow-lg">
            <div class="card-header card-header-indigo mt-n3 mx-3 bg-indigo">
              <h4 i18n class="card-title text-center text-white">Sign Up</h4>
              <p i18n class="card-category text-center text-white" >It’s quick and easy.</p>
            </div>
            <div class="card-body mx-3">
              <form [formGroup]="registrationForm" >


<!--                <div class="row">-->
<!--                  <div class="col">-->
<!--                    <br/>-->
<!--                    <mat-form-field class="full-width">-->
<!--                      <input matInput placeholder="first name" formControlName="firstName" />-->
<!--                    </mat-form-field>-->
<!--&lt;!&ndash;                    {{this.registrationForm.get('firstName').value}}&ndash;&gt;-->
<!--                  </div>-->
<!--                  &lt;!&ndash;</div>&ndash;&gt;-->

<!--                  &lt;!&ndash;<div class="row">&ndash;&gt;-->
<!--                  <div class="col">-->
<!--                    <br/>-->
<!--                    <mat-form-field class="full-width">-->
<!--                      <input matInput placeholder="last name" formControlName="lastName" [(ngModel)]="user.lastName">-->
<!--                    </mat-form-field>-->
<!--&lt;!&ndash;                    {{this.registrationForm.get('lastName').value}}&ndash;&gt;-->
<!--                  </div>-->
<!--                </div>-->

<!--                <div class="row">-->
<!--                  <div class="col">-->
<!--                    <span class="material-icons">face</span>-->
<!--                    <mat-form-field class="full-width">-->
<!--                      <input type="text" matInput placeholder="username" required formControlName="username"/>-->
<!--                      <mat-error *ngIf="registrationForm.get('username').invalid">-->
<!--                        Username is <strong>required</strong>-->
<!--                      </mat-error>-->
<!--                    </mat-form-field>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="row">-->
<!--                  <div class="col">-->
<!--                    <h4 class="card-title text-center">Sign Up</h4>-->
<!--                    <p class="card-category text-center">It’s quick and easy.</p>-->
<!--                  </div>-->
<!--                </div>-->

<!--                <div class="row">-->
<!--                  <div class="col">-->
<!--                    <mat-radio-group fxLayout formControlName="role" [(ngModel)]="selectedRole" class="example-radio-group">-->
<!--                      <mat-radio-button i18n  [value]="roleType.USER" value="{{roleType.USER}}"-->
<!--                                        class="example-radio-button " color="primary">-->
<!--                        I am a customer-->
<!--                      </mat-radio-button>-->
<!--                      <mat-radio-button i18n [value]="roleType.VENDOR" value="{{roleType.VENDOR}}"-->
<!--                                        class="example-radio-button " color="primary">-->
<!--                        I am a vendor-->
<!--                      </mat-radio-button>-->
<!--                    </mat-radio-group>-->

<!--                  </div>-->
<!--                </div>-->

                <div class="row mt-4">
                  <div class="col">
                    <mat-form-field class="w-100" appearance="outline">
                      <mat-label>
                        <span class="icon-font-24 me-1">
                          <mat-icon *ngIf="this.email.invalid" class="material-symbols-outlined">drafts</mat-icon>
                          <mat-icon *ngIf="this.email.valid" class="material-symbols-outlined">mail_outline</mat-icon>
                        </span>
                        <span i18n >Email</span>
                      </mat-label>
                      <input i18n-placeholder type="email" matInput placeholder="Verification required"
                             required formControlName="email" (keyup)="toLowerCaseEmail()" />
                      <mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row">
                  <div class="col">

<!--                    <mat-form-field class="full-width">-->
<!--                      <input type="password" matInput placeholder="password" required formControlName="password"/>-->
<!--                      <mat-error *ngIf="username.invalid">-->
<!--                        Password is <strong>required</strong>-->
<!--                      </mat-error>-->
<!--                    </mat-form-field>-->

                    <mat-form-field class="w-100" appearance="outline">
                      <mat-label>
                        <i class="icon-font-24 me-1">
                          <mat-icon *ngIf="this.password.invalid" class="material-symbols-outlined">lock_open</mat-icon>
                          <mat-icon *ngIf="this.password.valid" class="material-symbols-outlined">lock</mat-icon>
                        </i>
                        <span i18n>Password</span>
                      </mat-label>
                      <input i18n-placeholder matInput [type]="showPassword ? 'text' : 'password'"
                             placeholder="6 or more characters" formControlName="password"
                             (keypress.enter)="onSubmitRegisterForm()">
                      <button mat-icon-button matSuffix type="button"
                              (click)="showPassword = !showPassword"
                              [attr.aria-label]="'Hide password'"
                              [attr.aria-pressed]="showPassword">
                        <mat-icon class="material-symbols-outlined">
                          {{showPassword ? 'visibility' : 'visibility_off'}}</mat-icon>
<!--                        {{showPassword ? 'Hide' : 'Show'}}-->
                      </button>
                      <mat-error *ngIf="password.invalid">{{getErrorMessage()}}</mat-error>
                    </mat-form-field>
                  </div>
                </div>

<!--                <div class="row">-->
<!--                  <div class="col">-->
<!--&lt;!&ndash;                    <span class="input-group-addon">&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                     <i class="material-icons">lock_outline</i>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                      <i class="material-symbols-outlined">lock</i>&ndash;&gt;-->
<!--&lt;!&ndash;                    </span>&ndash;&gt;-->
<!--&lt;!&ndash;                    <mat-form-field class="full-width">&ndash;&gt;-->
<!--&lt;!&ndash;                      <mat-label>Re-type password</mat-label>&ndash;&gt;-->
<!--&lt;!&ndash;                      <input type="password" matInput placeholder="confirm password"&ndash;&gt;-->
<!--&lt;!&ndash;                             formControlName="confirmPassword" required />&ndash;&gt;-->
<!--&lt;!&ndash;                      <mat-error  *ngIf="registrationForm.get('confirmPassword').errors" >&ndash;&gt;-->
<!--&lt;!&ndash;                          <div *ngIf="registrationForm.get('confirmPassword').errors.required">Confirm Password is required</div>&ndash;&gt;-->
<!--&lt;!&ndash;                          <div *ngIf="registrationForm.get('confirmPassword').errors.mismatch">Passwords must match</div>&ndash;&gt;-->
<!--&lt;!&ndash;                      </mat-error>&ndash;&gt;-->
<!--&lt;!&ndash;                    </mat-form-field>&ndash;&gt;-->

<!--                    <mat-form-field class="full-width" appearance="outline">-->
<!--                      <mat-label> <span class="input-group-addon">-->
<!--&lt;!&ndash;                     <i class="material-icons">lock_outline</i>&ndash;&gt;-->
<!--                      <i class="material-symbols-outlined">lock</i>-->
<!--                    </span>Re-type password</mat-label>-->
<!--                      <input matInput [type]="showPasswordConfirm ? 'text' : 'password'"-->
<!--                             placeholder="Re-enter new password" formControlName="confirmPassword">-->
<!--                      <button mat-icon-button matSuffix type="button"-->
<!--                              (click)="showPasswordConfirm = !showPasswordConfirm"-->
<!--                              [attr.aria-label]="'Hide password'"-->
<!--                              [attr.aria-pressed]="showPasswordConfirm">-->
<!--                        <span class="material-symbols-outlined">-->
<!--                          {{showPasswordConfirm ? 'visibility' : 'visibility_off'}}</span>-->
<!--                      </button>-->
<!--                      <mat-error *ngIf="confirmPassword.invalid">{{getErrorMessage(null)}}</mat-error>-->
<!--                    </mat-form-field>-->


<!--                  </div>-->
<!--                </div>-->

<!--                <div class="row">-->
<!--                  <div class="col">-->
<!--                    <span class="material-icons">phone_android</span>-->
<!--                    <mat-form-field class="full-width">-->
<!--                      <input type="text" matInput placeholder="phone number" formControlName="phone"/>-->
<!--                    </mat-form-field>-->
<!--                  </div>-->
<!--                </div>-->

                <div class="row">
                  <div class="text-center">
                    <div class="fw-lighter py-3">
                      <span i18n>By signing up, you agree to our </span>
<!--                      <a i18n role="button" (click)="readTermsAndConditions()" class="text-decoration-none">-->
<!--                        Terms of Service,-->
<!--                      </a>-->
                      <a role="button" target="_blank" href="footer/general-conditions" class="text-decoration-none">
                        <span i18n>General conditions</span>,
                      </a>
                      <a role="button" target="_blank" href="footer/privacy-policy" class="text-decoration-none">
                        <span i18n>Privacy Policy</span>,
                      </a>
                      <a role="button" target="_blank" href="footer/cookie-policy" class="text-decoration-none">
                        <span i18n>Cookie policy</span>
                      </a>
                    </div>
                    <button i18n mat-raised-button color="primary" class="w-75"
                            [disabled] = "registrationForm.invalid"
                            (click)="onSubmitRegisterForm()">
                      Sign Up
                    </button>
                  </div>
                </div>

<!--                <div class="row pt-4">-->
                  <div class="pt-3 text-center " >
                    <button i18n mat-button routerLink="/login" color="primary">Already on Monochrome? Login</button>
                  </div>
<!--                </div>-->
              </form>

              <div class="text-center mt-2">
                <app-language-select [buttonType]="'button'"></app-language-select>
              </div>

            </div>
          </div>
        </div>
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
<!--</div>-->
