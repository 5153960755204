import {Injectable} from '@angular/core';
import {HttpClient, HttpStatusCode} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {ChangePassword} from './ChangePassword';
import {Observable} from 'rxjs';

const userProtectedUrl = environment.apiEndpoint + '/api/protected/user';

@Injectable()
export class AppUserProfileService {
  constructor(private http: HttpClient) {}

  changePassword(password: ChangePassword): Observable<HttpStatusCode> {
    return this.http.put<HttpStatusCode>(userProtectedUrl + '/password', password);
  }


}
