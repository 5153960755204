import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Contacts} from './Contacts';
import {PageMenu} from '../../../admin/admin-create-page/PageMenu';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';

const contactUrl = environment.apiEndpoint + '/api/protected/contact';
const contactPublicUrl = environment.apiEndpoint + '/api/public/contact';

@Injectable()
export class ContactsService {

  constructor(private http: HttpClient) {}


  createContact(contacts: Contacts) {
    return this.http.post<number>(contactUrl, contacts);
  }

  updateContact(contacts: Contacts) {
    return this.http.put(contactUrl, contacts);
  }

  readContacts(username: string) {
    let params = new HttpParams();
    params = params.append('username', username);
    return this.http.get<Contacts>(contactUrl, {params: params});
  }

  findAllContacts() {
    return this.http.get<Contacts[]>(contactUrl + '/all');
  }

  deleteContact(contactId: number) {
    return this.http.delete(contactUrl + '/' + contactId);
  }

  markAddressPrimary(contactId: number) {
    return this.http.get(contactUrl + '/primary/' + contactId);
  }

  findByDomainAndStoreName(domain: string, storeName: string): Observable<Contacts[]> {
    return this.http.get<Contacts[]>(contactPublicUrl + '/' + domain + '/' + storeName);
  }
}
