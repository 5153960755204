import {Component, Inject, Injectable, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBar, MatSnackBarHorizontalPosition} from '@angular/material/snack-bar';
import {AppStorageService} from '../storageService/app-storage.service';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {FormsModule} from '@angular/forms';
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import {MatDialog, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {AppCookieDialogComponent} from './dialog/app-cookie-dialog.component';
import {RouterModule} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-cookie-snackbar',
  templateUrl: './cookie-snackbar.component.html',
  styleUrls: ['./cookie-snackbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    MatCardModule,
    FormsModule,
    MatButtonModule,
    MatSlideToggleModule,
    MatDividerModule,
    MatDialogModule,
    RouterModule
  ],
})
export class CookieSnackbarComponent {

  horizontalPosition: MatSnackBarHorizontalPosition = 'end';

  constructor(
    // @Inject(MAT_SNACK_BAR_DATA) public data: string,
    // public _cookieDialogRef: MatDialogRef<AppCookieDialogComponent>,
    public _cookieDialog: MatDialog,
    private _snackBar: MatSnackBar,
    private storageService: AppStorageService,
  ) {
    // this.openSnackBar('', '');
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      horizontalPosition: this.horizontalPosition,
      panelClass: ['white-snackbar']
    });
  }

  openCookieDialog() {
    this._snackBar.dismiss();
    this._cookieDialog.open(AppCookieDialogComponent, {
      disableClose: true
    });
  }

  onCloseClick(): void {
    this._snackBar.dismiss();
  }

  onAcceptAllClick(): void {
    this._snackBar.dismiss();
    this.storageService.addCookieAccepted();

    this.storageService.setUserCookieSettings(this.acceptAllCookieSettings());
  }

  acceptAllCookieSettings() {
    return {
      allowRequired: true,
      allowAnalytic: true,
      allowFunctional: true,
      allowAdvertise: true
    };
  }

}
