import {MessageType} from './MessageType';

export class Message {
  body: string;
  type: MessageType;
  id?: string;

  constructor(init?: Partial<Message>) {
    Object.assign(this, init);
  }
}


