import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { User } from './User';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';

const baseUrl = environment.apiEndpoint + '/api/protected/user';
const baseUrlRegister = environment.apiEndpoint + '/api/public/register';

@Injectable()
export class UserService {
  constructor(private http: HttpClient) { }
// ne bi trqbvalo ima takava funkcionalnost
  // findAll() {
  //   return this.http.get<User[]>(baseUrl + '/users');
  // }


  findById() {
    return this.http.get<User>(baseUrl);
  }

  findByEmail(email: string): Observable<User> {
    let params = new HttpParams();
    params = params.append('email', email);
    return this.http.get<User>(baseUrl, {params: params});
  }

  registerUser(user: User) {
    return this.http.post(baseUrlRegister, user);
  }

  registerUserVendor(domain: string, user: User) {
    return this.http.post(baseUrlRegister + '/vendor/' + domain, user);
  }

  createUser(user: User) {
    return this.http.post(baseUrl, user);
  }

  updateUser(user: User) {
    return this.http.put(baseUrl, user);
  }

  delete(id: number) {
    return this.http.delete(baseUrl + '/' + id);
  }
}
