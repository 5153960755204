<h1 mat-dialog-title i18n>Term And Condition: {{termAndConditionParams.termAndCondition.title}}</h1>
<div mat-dialog-content #termAndConditionScrollVar (scroll)="onTermsAndConditionScroll($event)">
  <p [innerHTML]="termAndConditionParams.termAndCondition.content"></p>
</div>

<div mat-dialog-actions class="justify-content-end">
  <button i18n mat-button (click)="onCloseClick()">
    Close
  </button>
  <button i18n mat-raised-button *ngIf="showAcceptButton" color="primary"
          [mat-dialog-close]="termAndConditionParams.termAndCondition" cdkFocusInitial>
    Accept
  </button>
</div>
