// const apiEndpoint = 'https://stage.monochrome-digital.com';
// const apiEndpoint = 'https://mono-dev-bus3yqws7a-lm.a.run.app';
const apiEndpoint = '';

export const environment = {
  firebase: {
    apiKey: 'AIzaSyDVMyAbri3uRqzfjev6LKVBVUL_QPB_bJA',
    authDomain: 'monochrome-digital-stage.firebaseapp.com',
    projectId: 'monochrome-digital-stage',
    storageBucket: 'monochrome-digital-stage.appspot.com',
    messagingSenderId: '499188640411',
    appId: '1:499188640411:web:e28f2adbadf90cc798f43e'
  },
  production: false,
  apiUrl: apiEndpoint
  , apiEndpoint: apiEndpoint
  , apiEndpointImages: apiEndpoint + '/images'
  , apiStorePathImage: '/'
  , apiProtectedRootUrl: '/api/protected'
  , apiAdminRootUrl: '/api/admin'
  , apiPublicRootUrl: '/api/public'

};
