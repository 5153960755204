import {Component, Inject, LOCALE_ID} from '@angular/core';
import {User} from '../User';
import {UserService} from '../user.service';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MustMatch} from './MustMatch';
import {AppMessageService} from '../../message/app-message.service';
import {Router} from '@angular/router';
import {RoleType} from '../RoleType';
import {RoleDto} from '../RoleDto';
import {AppTermAndConditionService} from '../../app-term-and-condition/app-term-and-condition.service';
import {Subscription} from 'rxjs';
import {TermAndCondition} from '../../admin/admin-term-and-condition/TermAndCondition';
import {TermAndConditionDialog} from '../../app-term-and-condition/term-and-condition-dialog/term-and-condition-dialog';
import {MatDialog} from '@angular/material/dialog';
import {AuthenticationService} from '../../auth/authentication.service';
import {getLocaleId} from '@angular/common';
import {AppStorageService} from '../../storageService/app-storage.service';
import {AppCookieDialogComponent} from '../../cookie-snackbar/dialog/app-cookie-dialog.component';

@Component({
  selector: 'app-register',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
  providers: [ UserService ]
})
export class RegistrationComponent {

  registrationForm: FormGroup;
  user: User = new User();
  // roleType = RoleType;
  selectedRole: RoleType;
  roles: RoleDto[] = [];
  showPassword = false;
  showPasswordConfirm = false;
  currentLanguage = getLocaleId(this.locale);
  // currentLanguageLabel = getLanguageKeyByValue(this.storageService.getDomainString());
  registrationCurrentDomain = this.storageService.getDomainString();

  termAndCondition: TermAndCondition;
  termsAndConditionSubscription: Subscription;

  constructor(private router: Router,
              private fb: FormBuilder,
              private userService: UserService,
              private messageService: AppMessageService,
              private termAndConditionService: AppTermAndConditionService,
              private _termAndConditionDialog: MatDialog,
              private authenticationService: AuthenticationService,
              private storageService: AppStorageService,
              @Inject(LOCALE_ID) public locale: string) {

    this.selectedRole = RoleType.USER;

    this.registrationForm = this.fb.group({
        firstName: [''],
        lastName: [''],
        username: [''],
        password: ['', [Validators.required, Validators.minLength(6)]],
        confirmPassword: [''],
        email: ['', [Validators.required, Validators.email,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
          ]],
        phone: [''],
        role: [this.selectedRole, Validators.required]
      },
      // {
      //   // validators: this.password.bind(this)
      //   validators: MustMatch('password', 'confirmPassword')
      // }
      );
  }

  createUserRegistration() {
    const email1 = this.registrationForm.get('email').value;
    this.registrationForm.patchValue({
      username: email1,
      // formControlName2: myValue2 (can be omitted)
    });
    // this.registrationForm.get('role').value;
    // let userr = this.registrationForm.value;

    const regUser = this.registrationForm.value;
    // this.roles.push({id: null, name: this.selectedRole, description: null, isActive: true});
    // regUser.roles = this.roles;
    regUser.role = this.selectedRole;
    regUser.roles = [];
    regUser.roles.push({
      id: null,
      name: this.selectedRole,
      description: '',
      active: true,
      permissions: []});

    this.authenticationService.registrationFire(regUser.username, regUser.username, regUser.password)
      .subscribe(responseFire => {
        console.log('1111111111111111111-user is registration', responseFire);

        if (responseFire) {
          regUser.uid = responseFire.user.uid;
          regUser.isNewUser = responseFire.additionalUserInfo.isNewUser;
          // regUser.uid = responseFire.additionalUserInfo.providerId; = password
          this.authenticationService.setNewUser(responseFire.additionalUserInfo.isNewUser);

          this.userService.registerUser(regUser)
            .subscribe({
              next: response => {
                this.authenticationService.setNewUser(false);
                this.messageService.success('Your account is successfully created!');

                // const credentials = {username: this.email.value, password: this.password.value};
                // this.router.navigate(['/notification/confirm'], {state: {credentials: credentials}});

                // this.loginFromRegistration(responseFire);
                this.router.navigate(['/login']);
              }
            });
        }

      });

  }

  onSubmitRegisterForm() {
    // (keypress.enter) - for registration form keyup - not working (event hit twice)
    if (this.registrationForm.invalid) {
      return;
    }
    this.createUserRegistration();
  }
  // TODO
  loginFromRegistration(response) {
    const credentials = {username: this.email.value, password: this.password.value};
    this.authenticationService.setNewUser(false);

    this.authenticationService.loginWithFire(credentials).subscribe({
      next: (userCredentials) => {

        this.authenticationService.firebaseGetTokenResult(userCredentials)
          .subscribe({
            next: tokenResult => {
              console.log('===========REGISTER======AUTHORITIES===================', tokenResult.claims.authorities);
              this.authenticationService.setLoggedUserAuthorities(tokenResult.claims.authorities);
              this.redirectTo();
            }
          });
      }
    });
  }

  private redirectTo() {
    if (this.authenticationService.isAuthenticatedUser) {
      this.messageService.info('You have successfully registered and logged in');
      let redirect = '/user-profile';

      const loggedUserRole = this.storageService.getUserRole();
      if (loggedUserRole === RoleType.ADMIN || loggedUserRole === RoleType.VENDOR) {
        redirect = '/admin';
      }
      this.router.navigate([redirect]);
    }
  }

  getErrorMessage() {
    if (this.email.hasError('email')) {
      return $localize `Please enter a valid email`;
    }
    if (this.password.hasError('minlength')) {
      return $localize `Password must be 6 characters or more.`;
    }
    // if (this.confirmPassword.hasError('minlength')) {
    //   return 'Re-type password mismatch with password';
    // }

    return '';
  }

  // not user - just another approach for MustMatch.ts
  passwordConfirming(c: AbstractControl): { invalid: boolean } {
    if (c.get('password').value !== c.get('confirmPassword').value) {
      return {invalid: true};
    }
  }

  showTermsAndConditions() {
    this.openTermAndConditionDialog(this.termAndCondition);
  }

  openTermAndConditionDialog(term: TermAndCondition): void {
    const dialogRef = this._termAndConditionDialog.open(TermAndConditionDialog, {
      width: '750px',
      data: {termAndCondition: term}
    });

    dialogRef.afterClosed().subscribe(termAndCond => {
      if (termAndCond) {

      }
    });
  }

  readTermsAndConditions() {
    this.termsAndConditionSubscription = this.termAndConditionService.
    findAllTermAndConditionByDomainAndLanguage(this.registrationCurrentDomain, this.selectedRole, this.currentLanguage)
      .subscribe({
        next: terms => {
          if (terms) {
            this.termAndCondition = terms;
            this.showTermsAndConditions();
          }
        },
        error: error => {
          console.error(error);
        }
      });
  }

  openCookiePolicyRegistrationDialog() {
    this._termAndConditionDialog.open(AppCookieDialogComponent, { disableClose: false });
  }

  get firstName(): any {
    return this.registrationForm.get('firstName');
  }
  get lastName(): any {
    return this.registrationForm.get('lastName');
  }
  get username(): any {
    return this.registrationForm.get('username');
  }
  get email(): any {
    return this.registrationForm.get('email');
  }
  get password(): any {
    return this.registrationForm.get('password');
  }
  get confirmPassword(): any {
    return this.registrationForm.get('confirmPassword');
  }
  get phone(): any {
    return this.registrationForm.get('phone');
  }

  get registrationFrom() { return this.registrationForm.controls; }

  toLowerCaseEmail() {
    if (this.email.value) {
      this.email.setValue(this.email.value.toLowerCase());
      // this.registrationForm.controls['email'].setValue(this.email.value.toLowerCase());
    }
  }
}
