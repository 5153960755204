import {BrowserModule} from '@angular/platform-browser';
import {DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms'; // <-- NgModel lives here
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {Router, RouterModule, Routes} from '@angular/router';
import {MatDialogModule} from '@angular/material/dialog';

import {AppComponent} from './app.component';
import {HomeComponent} from './home/home.component';
import {AppService} from './app.service';
import {AppRoutingModule} from './app-routing.module';
import {AppCommonModule} from './common/app-common.module';
import {AuthenticationService} from './auth/authentication.service';
import {AuthModule} from './auth/auth.module';
import {AuthGuard} from './auth/auth-guard.service';
import {JwtTokenInterceptor} from './auth/JwtTokenInterceptor';
import {AboutUsModule} from './modules/about-us/about-us.module';
import {UserModule} from './user/user.module';
import {GoogleModule} from './google-modules/google.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatSliderModule} from '@angular/material/slider';
// import { AppNavigationComponent } from './app-navigation/app-navigation.component';
import {LayoutModule} from '@angular/cdk/layout';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {AppTableComponent} from './app-table/app-table.component';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatCardModule} from '@angular/material/card';
import {MatMenuModule} from '@angular/material/menu';
import {AppTreeComponent} from './app-tree/app-tree.component';
import {MatTreeModule} from '@angular/material/tree';
import {AppDragDropComponent} from './app-drag-drop/app-drag-drop.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
// import {AppAddressFormComponent} from './user/app-address-form/app-address-form.component';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatRadioModule} from '@angular/material/radio';
// import {BasicAuthInterceptor} from './auth/BasicAuthInterceptor';
import {ErrorInterceptor} from './auth/ErrorInterceptor';
// import {AppLayoutComponent} from './_layout/app-layout/app-layout.component';
import {AppHeaderComponent} from './_layout/app-header/app-header.component';
import {AppUserProfileComponent} from './user/profile/app-user-profile.component';

// import {AppItemModule} from './app-item/app-item.module';
// import {AppCartComponent} from './app-cart/app-cart.component';
import {AppItemDetailsComponent} from './app-item-details/app-item-details.component';
import {AppItemComponent} from './app-item/app-item.component';
import {AppItemDetailsService} from './app-item-details/app-item-details.service';
import {AppFavoriteComponent} from './app-favorite/app-favorite.component';
import {AppFavoriteService} from './app-favorite/app-favorite.service';
import {AppCartService} from './app-cart/app-cart.service';
import {AppCartComponent} from './app-cart/app-cart.component';
import {FavoriteButtonComponent} from './app-favorite/button/favorite-button.component';
// import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AppNavigationService} from './app-navigation/app-navigation.service';
// import {GoogleMapsModule} from '@angular/google-maps';
import {AppImageCropperModule} from './app-image-cropper/app-image-cropper.module';
import {AppImageCropperComponent} from './app-image-cropper/app-image-cropper.component';
// import {AppCheckoutComponent} from './app-checkout/app-checkout.component';
// import {AppCheckoutService} from './app-checkout/app-checkout.service';
import {AppMessageService} from './message/app-message.service';
import {MatBadgeModule} from '@angular/material/badge';
import {AppCookieComponent} from './common/cookie/app-cookie.component';
import {AppCookieService} from './common/cookie/app-cookie.service';
import {CookieInterceptor} from './auth/CookieInterceptor';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
// import {SideNavbarComponent} from './core/side-navbar.component';
// import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {RoleGuardService} from './auth/role-guard.service';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatStepperModule} from '@angular/material/stepper';
import {CdkAccordionModule} from '@angular/cdk/accordion';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatExpansionModule} from '@angular/material/expansion';
import { VendorComponent } from './vendor/vendor.component';
import {AppMessageComponent} from './message/app-message.component';
import {MenuListItemComponent} from './admin/admin-navigation/sidebar/menu-list-item/menu-list-item.component';
import {LoginComponent} from './user/login/login.component';
import {RegistrationComponent} from './user/registration/registration.component';
import { environment } from '../environments/environment';
import { provideFirestore, getFirestore } from '@angular/fire/firestore';
import { provideStorage, getStorage } from '@angular/fire/storage';
import {FileUpoloadModule} from './core/admin-file-upload/file-upoload.module';
import {AngularFireModule} from '@angular/fire/compat';
import {RegisterSellComponent} from './user/login-sell/register-sell.component';
import {JwtFireTokenInterceptor} from './auth/JwtFireTokenInterceptor';
import {AngularFireAuthModule} from '@angular/fire/compat/auth';

// export function HttpLoaderFactory(http: HttpClient) {
//   return new TranslateHttpLoader(http, environment.apiEndpoint + '/api/public/translation/exportFile/');
// }

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterSellComponent,
    RegistrationComponent,
    HomeComponent,
    // AppNavigationComponent,
    AppTableComponent,
    // AppTreeComponent,
    AppDragDropComponent,
    // AppAddressFormComponent,
    // AppLayoutComponent,
    AppHeaderComponent,
    // AppCheckoutComponent,
    AppCookieComponent,
    VendorComponent,
    AppMessageComponent

    // AppUserProfileComponent,
    // AppItemComponent,
    // AppItemDetailsComponent,
    // AppFavoriteComponent,
    // AppCartComponent,
    // FavoriteButtonComponent,
    // SideNavbarComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    // TranslateModule.forRoot({
    //   loader: {
    //     provide: TranslateLoader,
    //     useFactory: HttpLoaderFactory,
    //     deps: [HttpClient]
    //   }
    // }),
    AuthModule,
    AppRoutingModule,
    AppCommonModule,
    AboutUsModule,
    UserModule,
    GoogleModule,
    // GoogleMapsModule,
    BrowserAnimationsModule,
    MatSliderModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatTreeModule,
    DragDropModule,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    MatBadgeModule,
    MatCheckboxModule,
    CdkAccordionModule,
    MatExpansionModule,
    ReactiveFormsModule,
    // AppItemModule,
    // NgbModule,
    MatDialogModule,
    AppImageCropperModule,
    MatSnackBarModule,
    MatStepperModule,
    FileUpoloadModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule
  // export const auth = getAuth(app);
    // provideFirebaseApp(() => initializeApp(environment.firebase)), old way
    // provideFirestore(() => getFirestore()),
    // provideStorage(() => getStorage()),
    // AngularFireStorageModule,
  ],
  providers: [
    AuthGuard,
    RoleGuardService,
    AppService,
    AuthenticationService,
    AppCookieService,
    // CookieSnackbarComponent,
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: BasicAuthInterceptor,
    //   multi: true
    // },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: JwtTokenInterceptor,
    //   multi: true
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtFireTokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CookieInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    // AppCheckoutService,
    AppMessageService,
    // AppItemDetailsService,
    // AppFavoriteService,
    // AppCartService,
    // AppNavigationService,
    // {provide: LOCALE_ID, useValue: 'bg-BG'},
    // {provide: DEFAULT_CURRENCY_CODE, deps: [AppLocaleService], useFactory: (settingsService) => settingsService.get()}
    {provide: DEFAULT_CURRENCY_CODE, useValue: 'BGN'}
  ],
  bootstrap: [AppComponent],
  exports: [
  ]
})
export class AppModule {
  // Diagnostic only: inspect router configuration
  constructor(router: Router) {
    // Use a custom replacer to display function names in the route configs
    // const replacer = (key, value) => (typeof value === 'function') ? value.name : value;

    // console.log('Routes: ', JSON.stringify(router.config, replacer, 2));
  }
}
