import {NgModule} from '@angular/core';
import {ContactsComponent} from './contacts/contacts.component';
import {ContactsService} from './contacts/contacts.service';
import {GoogleModule} from '../../google-modules/google.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatButtonModule} from '@angular/material/button';
// import {GoogleMapsModule} from '@angular/google-maps';
import {CommonModule} from '@angular/common';
import {AppAboutUsComponent} from './app-about-us.component';
import {AppAboutUsService} from './app-about-us.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    GoogleModule,
    // GoogleMapsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule
  ],
  declarations: [
    ContactsComponent,
    AppAboutUsComponent
  ],
  providers: [
    ContactsService,
    AppAboutUsService
  ],
  exports: [
    // ContactsComponent
  ]
})
export class AboutUsModule {

}
