import { RoleType } from './RoleType';
import {FileInfo} from '../app-item/FileInfo';

export class User {
  id: number;
  uid: string;
  firstName: string;
  lastName: string;
  username: string;
  company: string;
  password: string;
  confirmPassword: string;
  email: string;
  token: string;
  phoneNumber: string;
  avatar: string;
  avatarFile: FileInfo;
  description: string;
  role: RoleType;
  isNewUSer: boolean;
}
