import {NgModule} from '@angular/core';
import {GoogleMapService} from './goole-map/google-map.service';
import {GoogleMapComponent} from './goole-map/google-map.component';


@NgModule({
  imports: [
  ],
  exports: [
    GoogleMapComponent
  ],
  declarations: [
    GoogleMapComponent
  ],
  providers: [
    GoogleMapService
  ]
})
export class GoogleModule {

}
